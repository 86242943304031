import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Block } from "./Home";

export const Footer = () => (
  <Block className="bg-light">
    <Row>
      <Col md={3}>
        <h5>click|app</h5>

        <ul className="list-unstyled text-small">
          <li>
            <Link to={`/`} className="text-muted">
              Home
            </Link>
          </li>
          <li>
            <Link to={`/react`} className="text-muted">
              For react developers
            </Link>
          </li>

          <li>
            <Link to={`/enterprise`} className="text-muted">
              For enterprise
            </Link>
          </li>
        </ul>
      </Col>

      <Col md={3}>
        <h5>Features</h5>

        <ul className="list-unstyled text-small">
          <li>
            <Link to={`/apps`} className="text-muted">
              Drag and drop UIs
            </Link>
          </li>
          <li>
            <Link to={`/apps`} className="text-muted">
              Connect to a data source or API
            </Link>
          </li>
          <li>
            <Link to={`/apps`} className="text-muted">
              Use hooks to add logic
            </Link>
          </li>
          <li>
            <Link to={`/apps`} className="text-muted">
              Download source code
            </Link>
          </li>
        </ul>
      </Col>

      <Col md={3}>
        <h5>Examples</h5>

        <ul className="list-unstyled text-small">
          <li>
            <Link to={`/examples/Table/_edit`} className="text-muted">
              Table
            </Link>
          </li>
          <li>
            <Link to={`/examples/Cards/_edit`} className="text-muted">
              Cards
            </Link>
          </li>
          <li>
            <Link to={`/examples/Dashboard/_edit`} className="text-muted">
              Dashboard
            </Link>
          </li>
          <li>
            <Link to={`/examples`} className="text-muted">
              Other examples
            </Link>
          </li>
        </ul>
      </Col>

      <Col md={3}>
        <h5>Docs</h5>

        <ul className="list-unstyled text-small">
          <li>
            <Link to={`/docs/get-started`} className="text-muted">
              Get started
            </Link>
          </li>
          <li>
            <Link to={`/docs/tutorial`} className="text-muted">
              Tutorial
            </Link>
          </li>
        </ul>
      </Col>
    </Row>
  </Block>
);
