import Storage from "./Storage";

const AppsStorage = {
  list() {
    const apps = Storage.topic("apps")
      .list()
      .filter((f) => !f.temp);
    return apps;
  },

  get(id) {
    return Storage.topic("apps").get(id);
  },

  save(app) {
    Storage.topic("apps").save(app.id, app);
  },

  delete(app) {
    Storage.topic("apps").delete(app.id);
  },

  count() {
    return Storage.topic("apps")
      .list()
      .filter((f) => !f.temp).length;
  },
};

export default AppsStorage;
