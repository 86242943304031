import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Image, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { IoReload } from "react-icons/io5";
import {
  RiCheckLine,
  RiCodeSSlashFill,
  RiDownload2Line,
  RiDragDropLine,
  RiLinksLine,
  RiShareForwardLine,
} from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import AppsStorage from "./AppsStorage";
import EmbeddedVideo from "./EmbeddedVideo";
import { ExampleList } from "./Examples";
import { Footer } from "./Footer";
import { HomeHeader as Header } from "./Header";

const Home = ({ banner = <CommonBanner />, lead = <CommonLead /> }) => {
  let history = useHistory();

  const [hasApps, setHasApps] = useState(false);

  useEffect(() => {
    setHasApps(AppsStorage.count() > 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>click|app</title>
      </Helmet>
      <Header />
      <Container>
        {/* <CommonBanner /> */}
        {banner}
        {lead}

        <Row className="mt-4 mb-2">
          <Col xs={12} className="text-center">
            {!hasApps && (
              <>
                <Button
                  className="btn btn-lg btn-primary"
                  onClick={(e) => {
                    import("./AppFactory").then(({ createApp }) => {
                      const app = createApp();
                      history.push(`/apps/${app.id}/_edit`);
                    });
                  }}
                >
                  Create an App
                </Button>

                <Link
                  to={`/examples/SpaceX/_edit`}
                  className="btn btn-lg btn-link ml-2"
                >
                  Start with an example
                </Link>

                <p
                  className="my-4 lead text-secondary"
                  style={{ fontSize: 14 }}
                >
                  free, no registration, no vendor lock-in
                  <br />
                  click|app generates a create-react-app based project for you
                </p>
              </>
            )}

            {hasApps && (
              <Link to="/apps" className="btn btn-lg btn-primary">
                My Apps
              </Link>
            )}
          </Col>
        </Row>

        <Row className="" style={{ marginBottom: 50, marginTop: 30 }}>
          <Col lg={{ span: 10, offset: 1 }} md={12}>
            {/* <Image
              fluid
              src="/demo3.gif"
              className="border rounded my-4 shadow"
            /> */}

            <EmbeddedVideo src="https://player.vimeo.com/video/556162858?muted=1&amp;loop=1&amp;autoplay=1" />

            {/* <div class="embed-responsive embed-responsive-16by9">
        
              <iframe
                className="embed-responsive-item"
                src="https://player.vimeo.com/video/556162858?muted=1&amp;loop=1&amp;autoplay=1"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div> */}
          </Col>
        </Row>
      </Container>

      <Block className="bg-light lead">
        <Header2>
          <GradientText>click|app</GradientText> can help you
        </Header2>
        <div className="text-center">
          <span>
            <RiCheckLine className="h2 text-muted mr-1" /> Develop complex UIs
            faster with wysiwyg editor
          </span>
          <span className="ml-4">
            <RiCheckLine className="h2 text-muted mr-1" /> Create interactive
            prototypes
          </span>{" "}
          <br />
          <span className="">
            <RiCheckLine className="h2 text-muted mr-1" /> A quickstart
            generator for react projects
          </span>
          <span className="ml-4">
            <RiCheckLine className="h2 text-muted mr-1" /> Build admin panels
            and dashboards — no coding required
          </span>
        </div>
      </Block>

      <Block>
        <Header2>Examples and Templates</Header2>
        <div className="my-4 text-center" style={{}}>
          Your work will be not sent to our server. The apps are stored only in
          your web browser and will persist even after the browser window is
          closed.
        </div>

        <ExampleList />
      </Block>

      <div style={{ height: 50 }} />
      <Footer />
    </>
  );
};

const CommonBanner = () => (
  <h1
    className="text-center my-4"
    style={{
      letterSpacing: "-.02em",
      fontSize: 70,
      fontWeight: 700,
      lineHeight: 0.9,
    }}
  >
    Build your
    <br />
    <GradientText>
      react<span style={{ fontSize: 5 }}> </span>+
      <span style={{ fontSize: 5 }}> </span>bootstrap
    </GradientText>{" "}
    app <br />
    without coding
  </h1>
);

const ReactBanner = () => (
  <h1
    className="text-center my-4 "
    style={{
      letterSpacing: "-.01em",
      fontSize: 62,
      fontWeight: 700,
      lineHeight: 1,
    }}
  >
    Visual design tool
    <br /> for developers to kick-start <br />
    <GradientText>
      react<span style={{ fontSize: 5 }}> </span>+
      <span style={{ fontSize: 5 }}> </span>bootstrap
    </GradientText>{" "}
    projects
  </h1>
);

const EnterpriseBanner = () => (
  <h1
    className="text-center my-4 pt-2"
    style={{
      letterSpacing: "-.0em",
      fontSize: 64,
      fontWeight: 700,
      lineHeight: 0.9,
    }}
  >
    Build internal apps
    <br /> without coding
  </h1>
);

const CommonLead = () => (
  <>
    {" "}
    <div
      className="text-center lead"
      style={{
        marginTop: 40,
        marginBottom: 0,
        fontWeight: "bold",
        // fontSize: 18,
      }}
    >
      click|app helps you to design and build complex UIs visually on top of
      real data
    </div>
    <div
      className="mt-3 mb-4 text-center lead text-muted"
      style={
        {
          // fontSize: 18
        }
      }
    >
      <RiDragDropLine className="h2  mr-1" /> Drag and drop UI components.
      <RiLinksLine className="h2  mr-1 ml-4" /> Connect your pages to a data
      source or API.
      <br className="" />
      <RiCodeSSlashFill className="h2  mr-1 ml-4" />
      Use hooks to add logic.
      <RiDownload2Line className="h2  ml-4 mr-1" />
      Download source code or a production-ready build of your app.
    </div>
  </>
);

export const Block = ({ className, title, children }) => (
  <div className={className} style={{ padding: "50px 0" }}>
    <Container className="">{children}</Container>
  </div>
);

const GradientText = ({ children }) => (
  <span
    style={{
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      backgroundImage:
        "linear-gradient(90deg, hsla(238, 100%, 71%, 1) 0%, hsla(295, 100%, 84%, 1) 100%)",
    }}
  >
    {children}
  </span>
);

const Header2 = ({ children }) => (
  <h2
    className="text-center mb-3"
    style={{
      letterSpacing: "-.02em",
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 0.9,
    }}
  >
    {children}
  </h2>
);

export default Home;

export const ReactHome = () => {
  return <Home banner={<ReactBanner />} />;
};

export const EnterpriseHome = () => {
  return <Home banner={<EnterpriseBanner />} lead={<EnterpriseLead />} />;
};

const EnterpriseLead = () => (
  <>
    <div
      className="text-center lead"
      style={{
        marginTop: 40,
        marginBottom: 0,
        fontWeight: "bold",
        // fontSize: 18,
      }}
    >
      click|app helps you to build, run and share apps on top of your company's
      data and services.
    </div>
    <div
      className="mt-3 mb-4 text-center lead text-muted"
      style={
        {
          // fontSize: 18
        }
      }
    >
      <RiDragDropLine className="h2  mr-1" /> Drag and drop UI components
      <RiLinksLine className="h2  mr-1 ml-4" /> Connect your pages to a data
      source or API
      <br className="" />
      <RiShareForwardLine className="h2  mr-1 ml-4" />
      Publish and share with your colleagues
      <IoReload className="h2  ml-4 mr-1" />
      Maintain and extend
    </div>

    <Row>
      <Col lg="3" sm="1"></Col>
      <Col lg="6" sm="10">
        <Alert variant="info" className="text-center">
          We're continuing to work to make the product enterprise-ready.
          <br /> Try our online UI builder to kick-start your project!
        </Alert>
      </Col>
    </Row>
  </>
);
