import classNames from "classnames";
import React, { useContext, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./Editor.css";
import { useModel } from "./Model";
import {
  findById,
  getPath,
  updateNode as updateTreeNode,
} from "./tree/SimpleTree";

export const EditorContext = React.createContext({});

const undoHistory = [];
var redoHistory = [];

const nodeProps = (node, state) => {
  const className = classNames({
    "gs-selected-node": state.isSelected,
    "gs-selected-drop-target": state.isOver,
    "gs-drop-target": state.canDrop,
    "gs-dragged-node": state.isDragging,
    "gs-editor-node": true,
    [`gs-editor-node-${node.type}`]: true,
  });
  return {
    className,
  };
};

const Editor = ({
  children,
  onSave,
  onChange,
  typeConfigs,
  hookConfigs,
  palette,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <EditorProvider
        onSave={onSave}
        onChange={onChange}
        typeConfigs={typeConfigs}
        hookConfigs={hookConfigs}
        palette={palette}
      >
        {children}
      </EditorProvider>
    </DndProvider>
  );
};

const updateSelection = (p, selection, setSelection) => {
  if (selection != null) {
    const s = findById(p, selection.id);

    if (!s) {
      setSelection(null);
    } else if (s !== selection) {
      setSelection(findById(p, selection.id));
    }
  }
};

const EditorProvider = ({
  children,
  onSave,
  onChange,
  typeConfigs,
  hookConfigs,
  palette,
}) => {
  const [selection, setSelection] = useState(null);
  const { componentTree, setComponentTree } = useModel();

  const saveModel = () => {
    onSave(componentTree);
  };

  const updateTree = (p, silentUpdate = false) => {
    console.log("Editor.updateModel ", p, silentUpdate);

    if (!silentUpdate) {
      undoHistory.push(componentTree);
    }
    redoHistory = [];
    setComponentTree(p);
    onChange && onChange(p);
    updateSelection(p, selection, setSelection);
  };

  const undo = () => {
    if (undoHistory.length > 0) {
      redoHistory.push(componentTree);
      const tree = undoHistory.pop();
      console.log("undo ", tree);
      setComponentTree(tree);
      updateSelection(tree, selection, setSelection);
    } else {
      console.log("undo is empty");
    }
  };

  const redo = () => {
    if (redoHistory.length > 0) {
      console.log("redo");
      undoHistory.push(componentTree);
      const tree = redoHistory.pop();
      setComponentTree(tree);
      updateSelection(tree, selection, setSelection);
    } else {
      console.log("redo is empty");
    }
  };

  const [activeSidebarTab, selectSidebarTab] = useState("palette");

  return (
    <EditorContext.Provider
      value={{
        selection,
        setSelection: (sel) => {
          setSelection(sel);
          selectSidebarTab(sel ? "inspector" : "palette");
          // if (sel) console.log("selectionJson", JSON.stringify(sel));
        },
        nodeProps: nodeProps,
        saveModel,
        undo,
        canUndo: undoHistory.length > 0,
        redo,
        canRedo: redoHistory.length > 0,
        activeSidebarTab,
        selectSidebarTab,
        componentTree,
        setComponentTree: updateTree,
        typeConfigs,
        hookConfigs,
        palette,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default Editor;

export const useEditor = () => {
  const editor = useContext(EditorContext);
  return editor;
};

export const useTypeConfig = (node) => {
  const { typeConfigs } = useEditor();
  return typeConfigs[node.type];
};

export const useHookConfigs = () => {
  const { hookConfigs } = useEditor();
  return hookConfigs;
};

export const useSelection = (item) => {
  const { setSelection, selection, componentTree: model } = useEditor();

  const isSelected = selection && selection === item;

  const setSelected = (e, simple = false) => {
    //console.log("setSelected", e, item, simple);

    e.preventDefault();
    e.stopPropagation();

    if (simple) {
      setSelection(item);
      return;
    }

    if (!e.altKey) {
      setSelection(item);
      return;
    }

    if (selection) {
      const path = getPath(model, item);
      const selectedIndex = path.indexOf(selection);
      console.log("selection", path);
      console.log("selectedIndex", selectedIndex);

      if (selectedIndex > 0) {
        setSelection(path[selectedIndex - 1]);
      } else {
        setSelection(item);
      }
    } else {
      setSelection(item);
    }
  };

  return {
    isSelected,
    setSelected,
    setSelection,
  };
};

export const useNodeEditor = (node, dnd) => {
  const { componentTree, setComponentTree } = useEditor();
  const { isSelected, setSelected, setSelection } = useSelection(node);

  const updateNode = (updateFunc, silentUpdate) => {
    console.log("updateNode", node.id);
    const tree = updateTreeNode(componentTree, node, (n) => {
      updateFunc(n);
    });
    setComponentTree(tree, silentUpdate);
  };

  const updateOption = (key, value) => {
    const oldValue = (node.options || {})[key];

    if (value !== oldValue) {
      console.log("updateOption", key, value, "old:", oldValue);
      const tree = updateTreeNode(componentTree, node, (n) => {
        if (n.options === null) n.options = [];
        n.options[key] = value;
      });
      setComponentTree(tree);
    }
  };

  const { nodeProps } = useContext(EditorContext);
  const { className, ...others } = nodeProps(node, {
    isSelected,
    ...dnd,
  });

  return {
    setSelected,
    setSelection,
    isSelected,
    updateNode,
    updateOption,
    dnd,
    className,
  };
};

export const useNodeLabel = (node) => {
  const { label, displayName = node.type } = useTypeConfig(node);
  return {
    type: displayName,
    label: label ? label(node) : null,
  };
};

export const defaultFactory = (type) => (options = {}, nodes = []) => ({
  type,
  options,
  nodes,
});

export const createTypeConfigs = (types) => {
  var mapping = [];

  const typesWithParent = (targetParent) => {
    return Object.entries(types)
      .filter(([k, v]) => {
        const { parent } = v;
        if (!parent) return true;

        if (Array.isArray(parent)) return parent.indexOf(targetParent) !== -1;

        return parent === targetParent;
      })
      .map(([k, v]) => k);
  };

  Object.keys(types).forEach((key) => {
    const { accept, parent, ...props } = types[key];

    var acceptedTypes = null;
    if (accept) {
      acceptedTypes = accept === "*" ? typesWithParent(key) : accept;
    }
    mapping[key] = { ...props, accept: acceptedTypes, type: key };
  });

  return mapping;
};


