import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import * as BsIcons from "react-icons/bs";
import { BsAlarm } from "react-icons/bs";
import { RiNumbersFill } from "react-icons/ri";
import { IconPicker } from "../react-bootstrap/react-bootstrap-editor/inspector/IconProp";

export const TestComp = ({ title }) => {
  return (
    <div className="bg-light d-flex vh-100">
      <Container className=" d-flex flex-column flex-fill p-4">
            <div className="bg-white">H1</div>

            <div className=" overflow-auto bg-danger  ">
              {Array.from(Array(100).keys()).map((_, index) => (
                <p key={index}>aaaa</p>
              ))}
            </div>
      
      
      </Container>
    </div>
  );
};


export const TestCompRow = ({ title }) => {
  return (
    <div className="bg-light d-flex vh-100">
      <Container className=" d-flex flex-column flex-fill p-4">
        <Row className=" flex-nowrap flex-fill1 h-100 p-4 bg-warning">
          <Col className=" d-flex flex-fill flex-column">
            <div className="bg-white">H1</div>
            <div className="bg-white">H1</div>
            <div className="bg-white">H1</div>

            <div className=" overflow-auto bg-danger  ">
              {Array.from(Array(100).keys()).map((_, index) => (
                <p key={index}>aaaa</p>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export const TestComp1 = ({ title }) => {
  return (
    <div className="bg-light d-flex flex-fill vh-100">
      <Container className="bg-white d-flex ">
        <Row className="bg-success flex-fill ">
          <Col className="bg-white">a</Col>
          <Col className="bg-danger d-flex flex-column align-items-stretch flex-shrink-0 ">
            <div className="d-flex bg-white">H1</div>

            <div className="  " style={{ overflowY: "auto" }}>
              {Array.from(Array(100).keys()).map((_, index) => (
                <p key={index}>aaaa</p>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default TestComp;
