function getKey(topic, id) {
  return `${topic}-@-${id}`;
}

const Storage = {
  topic: (topic) => ({
    list() {
      return Object.keys(localStorage)
        .filter((f) => f.startsWith(`${topic}-@`))
        .map((key) => {
          const item = window.localStorage.getItem(key);
          return item ? JSON.parse(item) : null;
        });
    },
    count() {
      console.log("!!!!!!!count",topic);
      return Object.keys(localStorage).filter((f) => f.startsWith(`${topic}-@`))
        .length;
    },
    save(id, obj) {
      obj.id = id;
      window.localStorage.setItem(getKey(topic, id), JSON.stringify(obj));
    },
    get(id) {
      const item = window.localStorage.getItem(getKey(topic, id));
      return item ? JSON.parse(item) : null;
    },
    delete(id) {
      window.localStorage.removeItem(getKey(topic, id));
    },
  }),
};

export default Storage;
