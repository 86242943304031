import { default as React } from "react";
import { Card, Container } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "./Footer";
import { HomeHeader } from "./Header";

export const ExampleList = ({}) => (
  <div className="row row-cols-1 row-cols-md-3 mt-4">
    <Example title="Table" id="Table" />
    <Example title="Cards" id="Cards" />
    <Example title="Dashboard" id="Dashboard" />

    <Example title="Data from an API" id="RestAPI" />
    <Example title="Modal" id="Modal" />
    <Example title="Table with filter" id="TableWithFilter" />

    <Example title="Form" id="Form" />
    <Example title="Simple table" id="SimpleTable" />
    <Example title="Toast" id="Toast" />

    <Example title="Navigation" id="NavBar" />
    <Example title="SpaceX Data" id="SpaceX" />
    <Example title="SpaceX Landpads" id="SpaceXLandpads" />
  </div>
);
const Example = ({ title, id }) => (
  <div className="col mb-4">
    <div className="card border-0">
      <div className="card-body pb-0 mb-0">
        <h5 className="card-title mb-0">{title}
        

        <Link  className="btn btn-outline-primary btn-sm float-right " style={{marginTop:-5}} to={`/examples/${id}/_edit`}>Edit</Link>
        
        </h5>
      </div>
      <Link to={`/examples/${id}/_edit`}>
        <img
          src={`/examples/${id}.png`}
          className="card-img-bottom"
          alt="..."
        />
      </Link>
    </div>
  </div>
);

const Examples = ({}) => {
  let history = useHistory();

  return (
    <>
      <Helmet>
        <title>Examples - click|app</title>
      </Helmet>

      <HomeHeader />

      <Container className="mt-4">
        <h1 className="h2  mb-3">Examples and Templates</h1>

        <ExampleList />

        <div style={{ marginBottom: 50 }} />

        <h2 className="h3 mb-3">Login Forms</h2>

        <div className="row row-cols-1 row-cols-md-3 mt-4">
          <Example title="Gradient" id="Login_Gradient" />
          <Example title="SVG" id="Login_Svg" />
          <Example title="Basic Form" id="Login_Basic" />

          
        </div>

        <div style={{ marginBottom: 100 }} />
      </Container>

      <Footer />
    </>
  );
};

export default Examples;
