import "bootstrap/dist/css/bootstrap.min.css";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Examples from "./Examples";
import Home, { EnterpriseHome, ReactHome } from "./Home";
import ScrollToTop from "./ScrollToTop";

import "../common/styles/bs.css"
import TestComp from "./Test";

//const Home = lazy(() => import("./Home"));
const AppContainer = lazy(() => import("./AppContainer"));
const Apps = lazy(() => import("./Apps"));
const ExampleContainer = lazy(() => import("./ExampleContainer"));
const GetStarted = lazy(() => import("./GetStarted"));
const Tutorial = lazy(() => import("./Tutorial"));

const ClickTool = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <ScrollToTop />
        <Switch>
          <Route path="/apps/:id">
            <AppContainer />
          </Route>
          <Route path="/examples/:id">
            <ExampleContainer />
          </Route>

          <Route path="/apps">
            <Apps />
          </Route>

          <Route path="/react">
            <ReactHome />
          </Route>

          <Route path="/enterprise">
            <EnterpriseHome />
          </Route>

          
          <Route path="/test">
            <TestComp />
          </Route>

          <Route path="/docs/get-started">
            <GetStarted />
          </Route>

          <Route path="/docs/tutorial">
            <Tutorial />
          </Route>

          <Route path="/examples">
            <Examples />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default ClickTool;
