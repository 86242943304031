import { useRef, useState } from "react";
import { Button, Form, Overlay, Popover } from "react-bootstrap";
import * as BsIcons from "react-icons/bs";
import { useInspector } from "../../../editor/Inspector";

export const IconProp = ({ label = "Icon", prop }) => {
  const { node, updateOption } = useInspector();

  var { options = {} } = node;

  return (
    <Form.Group>
      <Form.Label className="mr-2">{label}</Form.Label>
      <IconPicker
        value={options[prop] || ""}
        onChange={(e) => {
          updateOption(prop, e);
        }}
      />
    </Form.Group>
  );
};

export const IconPicker = ({ value, onChange }) => {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState(null);
  const [filteredKeys, setFilteredKeys] = useState(Object.keys(BsIcons));
  const target = useRef(null);

  const SelectedIcon = value && BsIcons[value];

  return (
    <>
      <Button
        size="sm"
        variant="outline-secondary"
        ref={target}
        onClick={(e) => {
          setShow(true);
        }}
      >
        {value ? <SelectedIcon /> : <>Select icon</>}
        <small className="ml-2">
          <BsIcons.BsFillCaretDownFill />
        </small>
      </Button>

      <Overlay
        show={show}
        target={target.current}
        rootClose
        onHide={() => setShow(false)}
        placement="bottom"
      >
        <Popover id="popover-basic">
          <Popover.Title as="p">
            <input
              className="form-control form-control-sm "
              placeholder="Search icons"
              value={query || ""}
              onChange={(e) => {
                setQuery(e.target.value);

                const re = new RegExp(".*" + e.target.value + ".*", "i");

                setFilteredKeys(
                  Object.keys(BsIcons).filter(
                    // (k) => k.indexOf(e.target.value) > -1
                    (k) => re.test(k)
                  )
                );
              }}
            />
          </Popover.Title>
          <Popover.Content
            style={{ maxHeight: 240, fontSize: 19 }}
            className="overflow-auto"
          >
            <a
              href="#"
              className="p-2"
              onClick={(e) => {
                e.preventDefault();
                onChange(null);
                setShow(false);
              }}
            >
              <small>No icon</small>
            </a>
            <br />

            {filteredKeys.map((k) => {
              const Icon = BsIcons[k];
              return (
                <a
                  key={k}
                  href="#"
                  className="p-2 text-dark"
                  onClick={(e) => {
                    e.preventDefault();
                    onChange(k);
                    setShow(false);
                  }}
                >
                  <Icon className="" alt={k} />
                </a>
              );
            })}
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};
