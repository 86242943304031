import React, { useContext, useState } from "react";

export const ModelContext = React.createContext({});

export const ModelProvider = ({ model, hooks = {}, types = {}, children }) => {
  //console.log("ModelProvider", model, hooks, types);
  const [componentTree, setComponentTree] = useState(model);

  const m = {
    componentTree,
    setComponentTree,

    hooks,
    types,
  };
  return <ModelContext.Provider value={m}>{children}</ModelContext.Provider>;
};

export const useModel = () => {
  const model = useContext(ModelContext);
  return model;
};

export const useHookTypes = () => {
  const { hooks } = useContext(ModelContext);
  return hooks;
};

export const useNodeType = (node) => {
  const { types } = useContext(ModelContext);
  return types[node.type];
};
