import produce from "immer";

const getChildren = (n) => n.nodes ?? [];
const setChildren = (n, children) => {
  n.nodes = children;
};
const eq = (n1, n2) => n1.id === n2.id;

//----

export const walk = (node, callback) => {
  dfs(node, (n) => {
    callback(n);
    return true;
  });
};

export const filter = (node, filterFn) => {
  var filtered = [];

  dfs(node, (n) => {
    if (filterFn(n)) {
      filtered.push(n);
    }
    return true;
  });
  return filtered;
};

export const asArray = (node) => {
  var filtered = [];
  dfs(node, (n) => {
    filtered.push(n);
    return true;
  });
  return filtered;
};

export const find = (node, fn) => {
  var found = null;
  const pre = (node) => {
    if (fn(node)) {
      found = node;
      return false;
    }
    return true;
  };

  dfs(node, pre);
  return found;
};

export const findById = (root, id) => {
  return find(root, (n) => n.id === id);
};

export const getPath = (root, n) => {
  var stack = [];

  const pre = (node) => {
    stack.push(node);
    if (eq(n, node)) {
      return false;
    }
    return true;
  };

  const post = (node) => {
    stack.pop();
    return true;
  };

  dfs(root, pre, post);

  return stack;
};

export const getParent = (root, n) => {
  var path = getPath(root, n);
  return path.length > 1 ? path[path.length - 1 - 1] : null;
};

export const indexInParent = (root, node) => {
  const targetParent = getParent(root, node);
  if (!targetParent) return -1;
  const targetChildren = getChildren(targetParent) ?? [];
  return targetChildren.indexOf(node);
};

const dfs = (node, preCallback = (n) => true, postCallback = (n) => true) => {
  var keepGoing = preCallback(node);
  if (keepGoing) {
    const nodes = getChildren(node);
    if (nodes) {
      nodes.forEach((child) => {
        if (keepGoing) {
          keepGoing = dfs(child, preCallback, postCallback);
        }
      });
    }
    if (keepGoing) keepGoing = postCallback(node);
  }
  return keepGoing;
};

//--------------------

export const updateNode = (root, node, d) => {
  return produce(root, (draft) => {
    const target = findById(draft, node.id);
    d(target);
  });
};

export const updateTree = (root, updateFunc) => {
  return produce(root, updateFunc);
};

export const removeNode = (root, node) => {
  return produce(root, (draft) => {
    var parent = getParent(draft, node);
    const newChildren = getChildren(parent).filter((n) => !eq(n, node));
    setChildren(parent, newChildren);
  });
};

export const addNode = (root, parent, node) => {
  return produce(root, (draft) => {
    const p = findById(draft, parent.id);
    const children = getChildren(p);
    setChildren(p, [...children, node]);
  });
};

export const insertNode = (root, parent, node, atIndex) => {
  return produce(root, (draft) => {
    const p = findById(draft, parent.id);
    const children = getChildren(p);
    children.splice(atIndex, 0, node);
    setChildren(p, [...children]);
  });
};

export const moveToNode = (root, parent, node, atIndex) => {
  return produce(root, (draft) => {
    //remove node from his parent
    var oldParent = getParent(draft, node);
    const newChildren = getChildren(oldParent).filter((n) => !eq(n, node));
    setChildren(oldParent, newChildren);

    const newParent = findById(draft, parent.id);
    var children = [...getChildren(newParent)];

    console.log("tree.moveToNode", atIndex);

    if (atIndex > -1) {
      children.splice(atIndex, 0, node);
    } else {
      children = [...children, node];
    }
    setChildren(newParent, [...children]);
  });
};

///--------
