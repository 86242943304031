import React from "react";

const EmbeddedVideo = ({ src }) => (
  <div class="embed-responsive embed-responsive-16by9">
    {/* <video loop controls muted autoPlay className="embed-responsive-item">
      <source src="/clickapp.mp4" type="video/mp4" />
    </video> */}

    <iframe
      className="embed-responsive-item"
      src={src}
      width="640"
      height="360"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
);

export default EmbeddedVideo;
