import React, { useContext } from "react";
import { DataHolder } from "./DataHolder";
import { useEditor } from "./Editor";
import {
  findById,
  getParent,
  getPath,
  removeNode,
  updateNode as updateTreeNode,
  updateTree,
} from "./tree/SimpleTree";

const InspectorContext = React.createContext({});

export const InspectorContextProvider = ({ children }) => {
  const {
    selection,
    setSelection,
    componentTree: model,
    setComponentTree: setModel,
    hookConfigs,
  } = useEditor();

  var parents = [];

  parents = getPath(model, selection);
  parents.pop();

  const updateNode = (updateFunc) => {
    const tree = updateTreeNode(model, selection, (draft) => {
      updateFunc(draft);
    });
    setModel(tree);
  };

  const updateModel = (updateFunc) => {
    const tree = updateTree(model, (draft) => {
      updateFunc(draft);
    });
    setModel(tree);
  };

  const embedIn = (newNode, newParent) => {

    const parent = newParent || newNode

    const node = selection;
    updateModel((tree) => {
      const p = getParent(tree, node);
      const index = p.nodes.findIndex((n) => n.id === node.id);
      p.nodes[index] = newNode;
      parent.nodes.push(node);
    });
    setSelection(newNode);
  };

  const addHook = (hook, hookOptions, onSuccess) => {
    const node = selection;
    updateModel((tree) => {
      const parents = getPath(tree, node);
      const comp = parents.find((p) => p.type === "component");
      if (comp) {
        if (!comp.hooks) comp.hooks = [];
        const h1 = {
          ...hookOptions,
          hook,
          id: "hook_" + new Date().getTime(),
        };
        comp.hooks.push(h1);
        hookConfigs[hook].onAdd && hookConfigs[hook].onAdd(comp, h1);

        const btn = findById(tree, node.id);
        onSuccess && onSuccess(btn, hookOptions);
      }
    });
  };

  const deleteNode = (e) => {
    console.log("deleteNode", e);
    const tree = removeNode(model, selection);
    setModel(tree);
    setSelection(null);
  };

  const updateOption = (key, value) => {
    const oldValue = (selection.options || {})[key];

    if (value !== oldValue) {
      console.log("updateOption", key, value, "old:", oldValue);
      const tree = updateTreeNode(model, selection, (n) => {
        if (!n.options) n.options = {};
        n.options[key] = value;
      });
      setModel(tree);
    }
  };

  const isRoot = parents.length === 0;

  return (
    <InspectorContext.Provider
      value={{
        node: selection,
        updateNode,
        deleteNode,
        isRoot,
        updateOption,
        updateModel,
        embedIn,
        addHook,
        parents,
      }}
    >
      {children}
    </InspectorContext.Provider>
  );
};

export const useInspector = () => {
  return useContext(InspectorContext);
};

export const getComponentVarName = (varName) => {
  var prefix = "";
  const { data = {} } = DataHolder.selection;

  if (data._component) {
    prefix = "_component.";
  }

  return `${prefix}${varName}`;
};
