import ReactJson from "react-json-view";

export const DataHolder = {};

export const useDataPreview = () => {
  const { data } = DataHolder["selection"] || {};

  if (!data) return null;

  if (Object.keys(data).length == 0) return null;

  const { props, ...otherData } = data;
  if (
    props &&
    otherData &&
    Object.keys(props).length == 0 &&
    Object.keys(otherData).length == 0
  )
    return null;

  return data;
};

export const DataPreview = ({ data }) => {
  if (!data) return null;

  return (
    <ReactJson
      src={data}
      name={false}
      displayDataTypes={false}
      quotesOnKeys={false}
      enableClipboard={false}
      groupArraysAfterLength={20}
      collapseStringsAfterLength={15}
      indentWidth={2}
      collapsed={1}
      style={{ borderLeft: "none" , fontSize:"0.72rem"}}
    />
  );
};
